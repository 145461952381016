import React, { useState } from 'react';
import './Interests.css';
import InterestsData from '../../assets/content/InterestsData.json';

const InterestsHeader = () => (
  <div className="interests-header">
    <h2>Interests</h2>
  </div>
);

const InterestCard = ({ interest, isExpanded, onClick }) => (
  <div
    className={`interest-card ${isExpanded ? 'expanded' : ''}`}
    onClick={onClick}
  >
    <div className="interest-card-icon">{interest.icon}</div>
    <div className="interest-card-title">{interest.title}</div>
    <div className="interest-card-description">
      {interest.description.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  </div>
);

const Interests = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleCardClick = index => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className='interests-container'>
      <section className="interests-section">
        <InterestsHeader />
        <div className="interests-box">
          {InterestsData.map((interest, index) => (
            <InterestCard
              key={index}
              interest={interest}
              isExpanded={index === expandedIndex}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </div>
      </section>
    </div>
  );
};

export default Interests;
