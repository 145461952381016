import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <div className="footer">
      <footer style={{ color: 'white', textAlign: 'center' }}>
        <p>© 2024 Eilon Cohen. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Footer;