import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import { useMediaQuery } from 'react-responsive';
import ContactMessage from '../assets/content/ContactMessage.json';
import '../styles/ContactPage.css';
import styled from 'styled-components'; 
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';
import Loading from '../components/contact/Loading';

const ContactHeader = styled.h2`
color: #fff;
font-size: 1.5rem;
font-weight: bold;
text-align: center;
`;

const ContactParagraph = styled.p`
color: #fff;
font-size: 1rem;
text-align: center;
`;

function ContactPage() {
  const MAX_MESSAGE_LENGTH = 1500;
  const MIN_MESSAGE_LENGTH = 10;
  const MAX_INPUT_LENGTH = 100;
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [errors, setErrors] = useState({});
  const [captchaVal,setCaptchaVal] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    socials: '',
    message: '',
  });

  const sendNotificationEmail = (e, sanitizedFormData) => {
    console.log('Sending notification email...');
    e.preventDefault();
    emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      {
        name: sanitizedFormData.name,
        company: sanitizedFormData.company,
        email_address: sanitizedFormData.email_address,
        url: sanitizedFormData.url,
        message: sanitizedFormData.message,
      },
      process.env.REACT_APP_EMAIL_USER_ID
    )
      .then((response) => {
        console.log('Email sent successfully:', response.status, response.text);
        setIsSuccess(true);
        setIsFailed(false);
      })
      .catch((error) => {
        console.error('Email failed to send:', error);
        setIsFailed(true);
        setIsSent(false);
      });
  }
  
  const sendAutoReplyEmail = (e, sanitizedFormData) => {
    console.log('Sending auto-reply email...');
    e.preventDefault();
    emailjs.send(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_AUTO_REPLY_TEMPLATE_ID,
      {
        name: sanitizedFormData.name,
        company: sanitizedFormData.company,
        email_address: sanitizedFormData.email_address,
        url: sanitizedFormData.url,
        message: sanitizedFormData.message,
      },
      process.env.REACT_APP_EMAIL_USER_ID
    )
      .then((response) => {
        console.log('Auto-reply email sent successfully:', response.status, response.text);
        setIsSuccess(true);
        setIsFailed(false);
      })
      .catch((error) => {
        console.error('Auto-reply email failed to send:', error);
        setIsFailed(true);
        setIsSent(false);
      });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const sanitizeInput = (input) => {
    // Convert the input into a text node to HTML encode any characters
    let tempDiv = document.createElement('div');
    tempDiv.appendChild(document.createTextNode(input));
    let sanitizedInput = tempDiv.innerHTML;
    tempDiv.remove();
    // Remove any remaining HTML tags
    sanitizedInput = sanitizedInput.replace(/<.*?>/gi, '');
    return DOMPurify.sanitize(sanitizedInput);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formErrors = {};

    // Name validation
    if (!formData.name || formData.name.length > MAX_INPUT_LENGTH) {
      if (!formData.name) {
        formErrors.name = 'Name is required';
      } else {
        formErrors.name = 'Name is too long';
      }
    }

    if ( formData.company && formData.company.length > MAX_INPUT_LENGTH) {
      formErrors.company = 'Company name is too long';
    }

    // Email validation
    if (!formData.email || formData.email.length > MAX_INPUT_LENGTH) {
      if (!formData.email) {
        formErrors.email = 'Email is required';
      } else {
        formErrors.email = 'Email address is too long';
      }
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = 'Email address is invalid';
    }

    // Message validation
    if (!formData.message || formData.message.length < MIN_MESSAGE_LENGTH) {
      formErrors.message = `Message must be at least ${MIN_MESSAGE_LENGTH} characters`;
    } else if (formData.message.length > MAX_MESSAGE_LENGTH) {
      formErrors.message = `Message must be less than ${MAX_MESSAGE_LENGTH} characters`;
    }

    // Update errors state
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      setIsSent(true);
      setIsLoading(true);
      // Sanitize and send form data
      const sanitizedFormData = {
        ...formData,
        name: sanitizeInput(formData.name),
        company: sanitizeInput(formData.company),
        email_address: sanitizeInput(formData.email),
        url: sanitizeInput(formData.socials),
        message: sanitizeInput(formData.message),
      };

      console.log('Form data is ready to be sent:', sanitizedFormData);
      sendNotificationEmail(e, sanitizedFormData);
      sendAutoReplyEmail(e, sanitizedFormData);
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  return (
    <div>
      {isMobile && <div style={{ height: '40px' }} />}
      <div className='contact-page-section'>
        <div className='contact-page-container'>
          {isSent ? ( 
            <div className='loading-box'>
              {isLoading && <Loading />}
              { isSuccess && !isLoading ? (
                <div className='success-box'>
                  <p className='success-text'>Your message has been sent successfully!</p>
                  <button className='back-home-button' onClick={() => document.location.href = '/'}>Back Home</button>
                </div>
              ) : null }
            </div>
          ) : (
            <form onSubmit={handleSubmit} noValidate className="contact-form">
            <ContactHeader>{ContactMessage.title}</ContactHeader>
            <ContactParagraph>{ContactMessage.description}</ContactParagraph>
              <input
                type='text'
                name='name'
                value={formData.name}
                onChange={handleChange}
                placeholder='Name'
                className={errors.name ? 'error' : ''}
              />
              {errors.name && <p className='error-text'>{errors.name}</p>}
  
              <input
                type='email'
                name='email'
                value={formData.email}
                onChange={handleChange}
                placeholder='Email Address'
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <p className='error-text'>{errors.email}</p>}
  
              <input
                type='company'
                name='company'
                value={formData.company}
                onChange={handleChange}
                placeholder='Company (Optional)'
                className={errors.company ? 'error' : ''}
              />
              {errors.company && <p className='error-text'>{errors.company}</p>}
   
              <input
                type='text'
                name='socials'
                value={formData.socials}
                onChange={handleChange}
                placeholder='Social Media or Website URL (Optional)'
              />
              
              <textarea
                name='message'
                value={formData.message}
                onChange={handleChange}
                placeholder='Your message (Required 10-1500 characters)'
                className={errors.message ? 'error' : ''}
              />
              {errors.message && <p className='error-text'>{errors.message}</p>}
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onChange={val => setCaptchaVal(val)}
              />
              { isFailed && <p className='error-text'>Failed to send message. Please try again.</p>}
              { !captchaVal ? <p className='error-text'>Please verify you are not a robot</p> : <button type='submit' className='submit-button' disabled={!captchaVal}>Send</button>}
            </form>
  
          ) }
        </div>
      </div>
    </div>
  );
}

export default ContactPage;