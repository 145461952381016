import React, { useState } from 'react';
import ProffesionalExpData from '../../assets/content/ProffesionalExpData.json';
import './ProffesionalExp.css';
import styled from 'styled-components';

const ProfExpHeader = styled.h2`
    font-family: 'Orbitron', sans-serif;
    color: #fff;
    font-size: 2em;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 1px;
`;

const ProffesionalExpHeader = () => (
  <ProfExpHeader>Professional Experience</ProfExpHeader>
);

const ProffesionalExpCard = ({ exp, isExpanded, onClick }) => (
  <div
    className={`proffesional-exp-card ${isExpanded ? 'expanded' : ''}`}
    onClick={onClick}
  >
    <div className="prof-card-header">
      <div className="prof-card-title">{exp.title}</div>
      <div className="prof-card-company">{exp.company}</div>
    </div>
    <div className="card-meta">
      <div className="card-location">{exp.location}</div>
      <div className="card-dates">{exp.startDate} - {exp.endDate}</div>
    </div>
    <ul className="prof-card-description">
      {exp.description.map((bullet, index) => (
        <li key={index}>{bullet}</li>
      ))}
    </ul>
  </div>
);

const ProffesionalExp = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleCardClick = index => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  return (
    <div className='proffesional-exp-container'>
      <section className="proffesional-exp-section">
        <ProffesionalExpHeader />
        <div className="proffesional-exp-box">
          {ProffesionalExpData.map((exp, index) => (
            <ProffesionalExpCard
              key={index}
              exp={exp}
              isExpanded={index === expandedIndex}
              onClick={() => handleCardClick(index)}
            />
          ))}
        </div>
      </section>
    </div>
  );
}

export default ProffesionalExp;
