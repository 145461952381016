// About.js
import React, { useState } from 'react';
import AboutContent from '../assets/content/AboutContent.json';
import '../styles/About.css';
// About component
const About = () => {
  const [expandedCard, setExpandedCard] = useState(null);

  const handleExpand = (index) => {
    if (expandedCard === index) {
      setExpandedCard(null);
    } else {
      setExpandedCard(index);
    }
  };

  return (
    <div className='about-container'>
      <div className="about-section">
        <div className="about-header">
          <h2>{AboutContent.title}</h2>
        </div>
        <div className="about-box">
          {AboutContent.content.map((section, index) => (
            <div 
              key={index} 
              className={`about-card ${expandedCard === index ? 'expanded' : ''}`}
              onClick={() => handleExpand(index)}
            >
              <h2 className="about-card-title">{section.title}</h2>
              <p className="about-card-description">
                {section.paragraph.split('\n').map((line, index) => (
                  <span key={index}>
                    {line}
                    <br />
                  </span>
                ))}
              </p>
            </div>
          ))}
        </div>
        <p className="closing-message">{AboutContent.closing.split('\n').map((line, index) => (
            <span key={index}>
              {line}
              <br />
            </span>
          ))}
        </p>
      </div>
    </div>

  );
};

export default About;