import React from 'react';
import './Education.css';
import EducationData from '../../assets/content/EducationData.json';


const EducationHeader = () => (
    <div className="education-header">
      <h2>Education</h2>
    </div>
  );

const EducationCard = ({ edu }) => (
<div className="edu-card">
    <div className="edu-card-meta">
        <div className="edu-card-header">
            <div className="edu-card-title">{edu.title}</div>
        </div>
        <div className="edu-loc-date">
            <div className="edu-card-dates">{edu.startDate} - {edu.endDate}</div>
            <div className="edu-card-location">{edu.location}</div>
        </div>
    </div>
    <div className="edu-card-description">
        {edu.description.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
        ))}
    </div>
</div>
);
  
const Education = () => {
    return (
        <div className=''>
            <section className="edu-section">
                <EducationHeader />
                <div className="edu-exp-box">
                    {EducationData.map((edu, index) => (
                        <EducationCard key={index} edu={edu}/>
                    ))}
                </div>
            </section>
        </div>
    );
}
    
export default Education;