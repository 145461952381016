import React from 'react';
import MainContent from '../components/home/MainContent';
import Interests from '../components/home/Interests';
import ProffesionalExp from '../components/home/ProffesionalExp';
import Education from '../components/home/Education';

function Home() {
    return (
        <div>
            <MainContent />
            <Interests />
            <ProffesionalExp />
            <Education />
        </div>
    );
}

export default Home;