import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import menuContent from '../assets/content/MenuContent.json';
import '../styles/Header.css';
const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
    console.log("Mobile menu:", isMobileMenuOpen);
  };


  return (
    <div className="header">
      <header className="relative text-white shadow-md overflow-hidden">
        <nav className="container mx-auto flex justify-between items-center py-3 px-4 md:px-6 relative z-10">
          {/* Brand Name */}
          <div className="brand-name">
            <Link to="/" className="text-xl md:text-2xl font-bold hover:text-gray-300 transition-colors" style={{ color: '#E4E4E4' }}>Eilon Cohen</Link>
          </div>          
          {/* Navigation Links */}
          <ul className="hidden md:flex space-x-2 md:space-x-4">
            {menuContent.map((item, index) => (
              <li key={index}>
                <Link to={item.path} className="nav-link text-sm md:text-base hover:text-gray-300 transition-colors">{item.name}</Link>
              </li>
            ))}
          </ul>
          {/* Mobile Menu Button */}
          <div 
            className={`mobile-menu md:hidden fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-20 ${isMobileMenuOpen ? 'block' : 'hidden'}`}
            onClick={toggleMobileMenu}
          >            
            <ul className="flex flex-col items-center justify-center h-full space-y-4">
              {menuContent.map((item, index) => (
                <li key={index}>
                  <Link to={item.path} className="nav-link text-xl font-bold hover:text-gray-300 transition-colors" onClick={toggleMobileMenu}>{item.name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <button onClick={toggleMobileMenu} className="md:hidden p-2 rounded-md">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path></svg>
          </button>
        </nav>
      </header>
    </div>
  );
};

export default Header;