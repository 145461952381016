import React from 'react';
import './MainContent.css';
import profilePic from '../../assets/images/eilon-cohen.jpeg';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTwitter, faTelegramPlane, faGithub } from '@fortawesome/free-brands-svg-icons';
import introContent from '../../assets/content/IntroContent.json';

const socialsLogo = (social) => {
    switch (social) {
        case 'faLinkedinIn':
            return faLinkedinIn;
        case 'faTwitter':
            return faTwitter;
        case 'faTelegramPlane':
            return faTelegramPlane;
        case 'faGithub':
            return faGithub;
        default:
    }
};

const Intro = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    if (isMobile) {
        return (
            <div className="intro-content">
                <div className="text-center">
                    <div className="profile-container">
                        <img
                            src={profilePic}
                            alt={introContent.name}
                        />
                    </div>
                    <h1 className="text-4xl font-bold mb-2 text-shadow">{introContent.name}</h1>
                    <div className="socials">
                        {introContent.socials.map((social, index) => (
                            <a
                                key={index}
                                href={social.url}
                                target="_blank"
                                rel="noreferrer"
                                className="social-icon"
                            >
                                <FontAwesomeIcon icon={socialsLogo(social.icon)} size="md" /> {/* Use socialsLogo function here */}
                            </a>
                        ))}
                    </div>
                    {introContent.details.map((detail, index) => (
                        <p key={index} className='details' >{detail}</p>
                    ))}
                    <div className="intro-paragraph">
                        {introContent.description.map((paragraph, index) => (
                            <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="intro-content">
                <div className="profile-container">
                    <img
                        src={profilePic}
                        alt={introContent.name}
                        className="rounded-full object-cover border-2 shadow-lg"
                    />
                </div>
                <div className="info-text">
                    <div className="name-socials-container">
                        <h1 className="name-title">{introContent.name}</h1>
                        <div className="socials">
                            {introContent.socials.map((social, index) => (
                                <a
                                    key={index}
                                    href={social.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="social-icon"
                                >
                                    <FontAwesomeIcon icon={socialsLogo(social.icon)} size="lg" />
                                </a>
                            ))}
                        </div>
                    </div>
                    <p className="details">{introContent.details.join(' | ')}</p>
                    {introContent.description.map((paragraph, index) => (
                        <p key={index} className="intro-paragraph">{paragraph}</p>
                    ))}
                </div>
            </div>
        );
    }
}

const MainContent = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    return (
        <div className="main-content">
            {isMobile && <div style={{ height: '75px' }} />}
            {Intro()}
            {isMobile && <div style={{ height: '50px' }} />}
        </div>
    );
};

export default MainContent;